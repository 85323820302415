<template>
  <card class="card"
        :class="textAlign"
        style="background-color: #1a1818;z-index: 1;min-height: 700px">

    <div class="card-body">
      <iframe
        scrolling="no"
        allowtransparency="true"
        :width="this.width"
        :height=this.height
        :src="`https://www.instagram.com/p/${this.postId}/embed?theme=dark`"
        frameborder="0"></iframe>
      <div
        class="card-header">
        <div class="card-title">
          <h1 class="tulum-party-title">{{this.title}}</h1>
        </div>
      </div>
      <div v-if="this.content" v-html="this.content"></div>
    </div>

    <div class="card-link text-center">
      <a v-if="this.link && this.actionLabel" :href="`${this.link}`"
         @click="clickLog"
         class="btn btn-primary btn-sm " style="margin-top: 15px">{{ this.actionLabel }}</a>
    </div>
  </card>
</template>
<script>
export default {
  name: 'instagram-card',
  props: {
    title: {
      type: String,
      description: 'Card title',
      default: ''
    },
    subTitle: {
      type: String,
      description: 'Card subtitle',
      default: ''
    },
    label: {
      type: String,
      description: 'Card label',
      default: ''
    },
    content: {
      type: String,
      description: 'Card content',
      default: undefined
    },
    align: {
      type: String,
      default: 'left',
      description: 'Text Alignment',
    },
    link: {
      type: String,
      description: 'Link on Action',
      default: ''
    },
    action: {
      type: String,
      description: 'Action',
      default: ''
    },
    actionLabel: {
      type: String,
      description: 'Card subtitle',
    },
    postId: {
      type: String,
      description: 'Card subtitle',
    },
    width: {
      type: Number,
      description: 'Card subtitle',
      default: 300
    },
    height: {
      type: Number,
      description: 'Card subtitle',
      default: 300
    },
  },
  methods: {
    clickLog() {
      this.$rollbar.info(`Instagram Card Click: ${this.title}`);
    },
  },
  computed: {
    textAlign() {
      return 'text-' + this.align;
    },
  },
};
</script>
